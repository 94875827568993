/* Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
    padding: 10px 20px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  .logo img {
    width: 40px; /* Adjust the width to your desired size */
    height: auto;
  }
  .mobile-menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
    transition: transform 0.2s ease; /* Add a smooth animation */
  }
  
  /* Show the mobile menu on smaller screens */
  @media (max-width: 768px) {
    .header {
      flex-direction: column; /* Stack items vertically */
    }
  
    .logo {
      order: 2; /* Move the logo to the bottom */
    }
    .logo img {
        width: 40px; /* Adjust the width to your desired size */
        height: auto;
      }
    .mobile-menu-icon {
      display: flex;
      order: 1; /* Move the menu icon to the top */
    }
  
    .nav {
      display: none;
    }
  
    .nav.open {
      display: block;
      text-align: center;
    }
  
    .nav ul {
      list-style: none;
      padding: 0;
    }
  
    .nav li {
      margin-bottom: 10px;
    }
  
    .bar.open:nth-child(1) {
      transform: translateY(6px) rotate(-45deg);
    }
  
    .bar.open:nth-child(2) {
      opacity: 0;
    }
  
    .bar.open:nth-child(3) {
      transform: translateY(-6px) rotate(45deg);
    }
  }

  .pressable-button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 0;
    font-size: 1.4rem; /* Adjust the font size as needed */
  }
  
  .pressable-button:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light gray background on hover */
    color: #000; /* Black text color on hover */
  }
  
  .pressable-button:active {
    background-color: rgba(0, 0, 0, 0.2); /* Slightly darker background when pressed */
  }
  