*{
  padding: 0;
  margin: 0;
  box-sizing: content-box;
  width: 100%;
  
}

body{
  width: 100%;
  font-family: sans-serif;
}

.app{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
