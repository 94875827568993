/* Add these styles to your existing CSS file */

/* Style the contact section container */
.contact-section {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
  }
  
  /* Style the contact title */
  .contact-title {
    font-size: 2rem;
    margin-bottom: 100px; /* Add spacing between the title and social media links */
  }
  
  /* Style the social media links */
  .social-buttons {

    gap: 20px; /* Adjust the gap between social media icons */
  
    /* Add a media query for mobile screens */
    @media (max-width: 768px) {
      flex-direction: column; /* Change to a column layout */
      align-items: flex-start; /* Align items to the start of the column */
    }
  }
  
  .social-buttons a {
    text-decoration: none;
  }
  
  .social-buttons img {
    width: 50px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .social-buttons img:hover {
    transform: scale(1.2);
  }
  