/* ScrollToTopButton.css */

.scroll-to-top-button {
    width: 40px;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000;
    color: #fff;
    font-size: 24px;
    border: none;
    border-radius: 200%;
    padding: 10px 15px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    
  }
  
  .scroll-to-top-button.visible {
    display: block;
  }
  
  /* Style the button's hover effect (optional) */
  .scroll-to-top-button:hover {
    background-color: #000;
  }
  