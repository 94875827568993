/* YouTubeVideo.css */

.youtube-video-container {
    width: 80%; /* Adjust the width as needed */
    max-width: 800px; /* Set a maximum width if desired */
    margin: 0 auto; /* Center the container horizontally */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
    border-radius: 10px; /* Add rounded corners */
    overflow: hidden; /* Hide overflowing content */
    position: relative; /* Positioning for padding-bottom hack */
    padding-bottom: 56.25%; /* Aspect ratio for a 16:9 video (9 / 16 * 100%) */
  }
  
  .youtube-video-container iframe {
    position: absolute; /* Position the iframe absolutely */
    top: 0;
    left: 0;
    width: 100%; /* Fill the container width */
    height: 50%; /* Fill the container height */
    border: none; /* Remove iframe border */
  }
  