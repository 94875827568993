/* Add these styles to your existing CSS file */

/* Style the video section */
.video-section {
    background-color: #333; /* Dark gray background */
    color: #fff;
    padding: 80px 0;
    text-align: center;
  }
  
  /* Style the video container */
  .video-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Style the video element */
  .video-container video {
    width: 100%;
    height: auto;
    outline: none;
  }
  
  /* Style video controls (optional) */
  .video-container video::-webkit-media-controls {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
  }
  
  /* Style video controls for Firefox (optional) */
  .video-container video::-moz-media-controls {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
  }
  
  .cta-button:hover {
    background-color: #f85959; /* Darker gray on hover */
  }
  
  @media (max-width: 768px) {
    .cta-button {
      background-color: #000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 1.2rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      max-width: 200px; /* Limit the button width */
    }
    .fixed-button-container {
      position: absolute;
      bottom: 50px; /* Adjust the distance from the bottom as needed */
    }  
  }

  .cta-button {
    background-color: #f70505;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    max-width: 200px; /* Limit the button width */
    
  }
  .fixed-button-container {
    position: relative;
    bottom: 1px; /* Adjust the distance from the bottom as needed */
  }
  .cta-button:hover {
    background-color: #f85959; /* Darker gray on hover */
  } 
  
