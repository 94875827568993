footer{
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: rgb(80, 77, 77);
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}

.logo-footer{
    margin-right: 20px;
    white-space: nowrap;
    text-decoration: none;
    font-size: 15px;
    color: #FFF;
    font-weight: bold;
}