/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Define the background color for the whole page */
  body {
    background-color: #f5f5f5; /* Light gray background */
    font-family: Arial, sans-serif;
  }
  
  /* Style the header/navigation bar */
  header {
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
    padding: 20px;
  }
  
  nav ul {
    list-style: none;
  }
  
  nav ul li {
    display: inline;
    margin-right: 20px;
  }
  
  nav a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  
  /* Style the hero section */
  .hero {
    background-image: url('./HomePage.png'); /* Replace with your background image */
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 100px 0;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  
  .cta-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    max-width: 200px; /* Limit the button width */
  }
  .fixed-button-container {
    position: absolute;
    bottom: 50px; /* Adjust the distance from the bottom as needed */
    left: 10px; /* Adjust the distance from the left as needed */
    z-index: 1000; /* Ensure it appears above other content */
  }
  .cta-button:hover {
    background-color: #333; /* Darker gray on hover */
  }
  
  /* Style the features section */
  .features {
    padding: 80px 0;
    text-align: center;
  }
  
  .features h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #000; /* Black text color */
  }
  
  .feature {
    margin-bottom: 40px;
  }
  
  .feature img {
    max-width: 100px;
    margin-bottom: 20px;
  }
  
  /* Style the footer */
  footer {
    background-color: #333; /* Dark gray background */
    color: #fff;
    text-align: center;
    padding: 20px 0;
  }
  
  /* Responsive styles (adjust as needed) */
  @media (max-width: 768px) {
    header {
      text-align: center;
    }
  
    nav ul {
      text-align: center;
      margin-top: 20px;
    }
  
    nav ul li {
      display: block;
      margin-bottom: 10px;
    }
  
    .hero {
      padding: 60px 0;
    }
  }
  
  /* Ensure each section occupies the entire viewport height */
.hero,
.features,
.video-section,
#contact-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cta-button.gold {
    background-color: gold;
    color: #000; /* Change text color to black for better contrast */
  }

/* Add these styles to your existing CSS file */

/* Style the feature section container */
.features {
  padding: 80px 0;
  text-align: center;
}

/* Style the feature list as a flex container */
.features .feature-list {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start of the column */
  flex-wrap: wrap; /* Wrap items onto a new row when there's not enough space */
  gap: 20px; /* Adjust the gap between items as needed */
}

/* Style individual feature items */
.features .feature-list .feature-item {
  width: calc(50% - 10px); /* 2 columns, minus the gap */
  background-color: #f5f5f5; /* Light gray background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  text-align: center;
  transition: transform 0.3s ease; /* Add a hover effect */
}

.features .feature-list .feature-item:hover {
  transform: translateY(-5px);
}

/* Style the feature list as a column on mobile screens */
@media (max-width: 768px) {
  .hero {
    background-image: url('./HomePage.png'); /* Replace with your background image */
    background-size: contain;
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center center;
    color: #fff;
    padding: 100px 0;
    text-align: center;
  }

  .features .feature-list {
    display: flex;
    
    justify-content: center;
    align-items: flex-start; /* Align items to the start of the column */
    flex-wrap: wrap; /* Wrap items onto a new row when there's not enough space */
    gap: 20px; /* Adjust the gap between items as needed */
  }

  .features .feature-list .feature-item {
    width: calc(50% - 10px); /* 2 columns, minus the gap */
    background-color: #f5f5f5; /* Light gray background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease; /* Add hover effect */
  }
  
  .features .feature-list .feature-item:hover {
    transform: translateY(-5px);
    background-color: #ddd; /* Gray background on hover */
  }

  .cta-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    max-width: 200px; /* Limit the button width */
  }
  .fixed-button-container {
    position: fixed;
    bottom: 50px; /* Adjust the distance from the bottom as needed */
  }  
}

.cta img {
  width: 60px; /* Adjust the width to your desired size */
  height: auto;
}

.public{
  width: 150px; /* Adjust the width to your desired size */
  height: 100px;
}

.conquistas{
  width: 100%; /* Adjust the width to your desired size */
  height: 150px;
}